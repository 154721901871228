<template>
    <div class="banner_box">

        <div v-if="isList == true">
            <el-carousel :interval="5000" indicator-position="none">
                <el-carousel-item v-for="(item, index) in bannerImgUrl" :key="index">
                    <div class="bg">
                        <img class="pointer banner_img" :src="item" alt="" />

                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <img v-else class="banner_img" :src="bannerImgUrl" alt="" />
    </div>
</template>
<script>
export default {
    props: {
        bannerImgUrl: {},
        isList: {},
    },
    data() {
        return {
        }
    },
    // 监听路由变化
    watch: {
        $route: "urlName",
    },
    created() {
    },
    methods: {


    }
}
</script>
<style lang="scss" scoped>
.banner_box {
    min-width: 1200px;
    height: 540px;

    ::v-deep.el-carousel__container {
        height: 540px;
    }

    ::v-deep.el-carousel {
        .el-carousel__container {
            .el-carousel__arrow {
                width: 60px;
                height: 60px;
                font-size: 24px;
                background: rgba(51, 51, 51, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .banner_img {
        width: 100%;
        height: 540px;
    }
}
</style>