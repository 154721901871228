<template>
    <div class="download_info_div">
        <ul class="download_info_ul flex flex-wrap">
            <li class="download_info_li pointer display column" v-for="(item, index) in freeData" :key="index">
                <img :src="item.materialImgUrl" alt="" />
                <div class="name display">{{ item.materialName }}</div>
            </li>
        </ul>
        <div class="btn display" @click="service">立即下载免费资料</div>
    </div>
</template>
<script>
export default {
    props: {
        freeData: [],
    },
    data() {
        return {}
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.download_info_div {
    background: #ffffff;
    margin-top: 31px;
    padding-top: 50px;
    padding-bottom: 50px;

    .download_info_ul {
        .download_info_li {
            width: 230px;
            margin-bottom: 46px;
            padding-left: 2px;

            img {
                width: 70px;
                height: 70px;
            }

            .name {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                text-align: center;
                margin-top: 20px;
            }
        }
    }

    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 5px;
    }
}
</style>