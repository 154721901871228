<template>
    <div class="problem_solving_div">
        <ul class="Between flex-wrap">
            <li class="Between problem_solving_li" v-for="(item, index) in ProblemSolvingData" :key="index">
                <div class="fl flex-center">
                    <div class="num display">0{{ index + 1 }}</div>
                    <div class="problem">{{ item.questionName }}</div>
                </div>
                <div class="fr display pointer" @click="service">立即咨询 >></div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        ProblemSolvingData: {},
    },

    data() {
        return {}
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.problem_solving_div {
    margin-top: 31px;
    // margin-bottom: 50px;
    background: #fafafa;
    padding-bottom: 89px;

    ul {
        .problem_solving_li {
            width: 590px;
            height: 54px;
            background: #023a7c;
            border-radius: 27px;
            margin-bottom: 28px;

            .fl {
                .num {
                    width: 42px;
                    height: 42px;
                    background: #ff881e;
                    border-radius: 50%;
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #ffffff;
                }

                .problem {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    margin-left: 14px;
                }
            }

            .fr {
                width: 160px;
                height: 46px;
                background: #ff881e;
                border-radius: 23px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-right: 6px;
            }
        }
    }
}
</style>