<template>
    <div class="applyHouseholdAccount_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content">
                <CourseTitle :title="formData.preferentialPoliciesName"> </CourseTitle>
                <div class="preferentialPolicies_div">
                    <ul>
                        <li class="preferentialPolicies_li" v-for="(
								item, index
							) in formData.deepHouseholdPreferentialPoliciesS" :key="index">
                            <div class="name_div">{{ item.preferentialPoliciesName }}</div>
                            <div class="text_div">
                                {{ item.preferentialPoliciesIntroduce }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn display pointer" @click="service">了解更多优惠政策</div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.categoryEntryName"> </CourseTitle>
                <div class="category_div">
                    <ul class="display">
                        <li class="category_list" v-for="(item, index) in formData.deepHouseholdCategoryEntrys"
                            :key="index">
                            <img :src="item.categoryEntryImgUrl" alt="" />
                            <div class="list_cont display column">
                                <div class="list_title">{{ item.categoryEntryName }}</div>
                                <div class="list_text">
                                    {{ item.categoryEntryIntroduce }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle :title="formData.hotWayName"> </CourseTitle>
                <div class="way_div flex">
                    <div class="way_div_fl">
                        <ul class="flex flex-wrap">
                            <li class="way_fl_li flex-center" v-for="(item, index) in formData.deepHouseholdHotWays"
                                :key="index">
                                <img :src="item.hotWayImgUrl" alt="" />
                                <div class="way_contetn">
                                    <div class="head flex">
                                        <div class="num">0{{ index + 1 }}</div>
                                        <div class="title">{{ item.hotWayName }}</div>
                                    </div>
                                    <div class="way_text">{{ item.hotWayIntroduce }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="way_div_fr">
                        <img :src="formData.hotWayImgUrl" alt="" />
                    </div>
                </div>
                <div class="btn display pointer" @click="service">点击了解更多方式</div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.takeTestName"> </CourseTitle>
            <div class="content">
                <TakeTest :takeTestData="formData.specialQuestionBankVOS"></TakeTest>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <div class="housekeeper_div">
                    <CourseTitle :title="formData.processManagerName"> </CourseTitle>
                    <ul class="housekeeper_ul flex flex-wrap">
                        <li class="housekeeper_li display column"
                            v-for="(item, index) in formData.deepHouseholdProcessManagers" :key="index">
                            <div class="service_way">{{ item.processManagerName }}</div>
                            <div class="btn display pointer" @click="service">点击咨询</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.dominantInterestName"> </CourseTitle>
            <div class="content security_interest">
                <ul class="flex flex-wrap">
                    <li class="security_interest_list flex-center"
                        v-for="(item, index) in formData.deepHouseholdDominantInterests" :key="index">
                        <img :src="item.dominantInterestImgUrl" alt="" />
                        <div class="list_content">{{ item.dominantInterestName }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.materialDownloadName" :isWhite="1">
            </CourseTitle>
            <div class="content">
                <ObtainData :freeData="formData.jobSalutationMaterials"></ObtainData>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import ObtainData from '@/components/obtainData.vue'
import ProblemSolving from '@/components/problemSolving.vue'
import TakeTest from '@/components/takeTest'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ObtainData,
        ProblemSolving,
        TakeTest,
    },
    data() {
        return {
            formData: {},
            list: [
                {
                    id: 1,
                    name: '公租房、安居房',
                    text: '符合条件的深户人员可以申请深圳的公租房、安居房',
                },
                {
                    id: 2,
                    name: '在职医保',
                    text: '深户购买一档医保，住院、门诊、孕产、开药报销比例高',
                },
                {
                    id: 3,
                    name: '少儿医保',
                    text: '符合条件的深户人员可以申请深圳的公租房、安居房',
                },
                {
                    id: 4,
                    name: '子女入学',
                    text: '深户学生申请入读深圳公办学校更容易',
                },
                {
                    id: 5,
                    name: '车牌摇号/竞价',
                    text: '拥有深圳户口，可以直接参加粤B车牌摇号/竞价，不要求居住证和社保；非深户人员参加粤B车牌摇号/竞价，要求持有有效居住证+最近连续24个月以上缴纳深圳医保（不含补缴）',
                },
                {
                    id: 6,
                    name: '买房',
                    text: '深户家庭落户3年+连续缴纳36个月及以上个人所得税或社会保险证明即可购买商品住房，而非深户人员则要求连续缴纳5年及以上个人所得税或社会保险证明',
                },
            ],
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.householdAccountApi().then((res) => {
                console.log(res)
                this.formData = res.data
            })
        },

        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.applyHouseholdAccount_page {
    .module1 {
        height: 1150px;
        background: url('../../assets/img/applyHouseholdAccount/bg1.jpg') no-repeat;
        background-size: 100% 100%;

        .content {
            height: 1150px;
            background: url('../../assets/img/applyHouseholdAccount/bg2.png') no-repeat;
            background-size: 100% 100%;
        }

        .preferentialPolicies_div {
            position: relative;

            .preferentialPolicies_li:nth-child(1) {
                top: 90px;
                left: 471px;

                .name_div {
                    margin-left: 70px;
                }

                .text_div {
                    width: 272px;
                    margin-top: 24px;
                }
            }

            .preferentialPolicies_li:nth-child(2) {
                top: 200px;
                left: 753px;

                .name_div {
                    margin-left: 65px;
                }

                .text_div {
                    width: 240px;
                    margin-top: 32px;
                }
            }

            .preferentialPolicies_li:nth-child(3) {
                top: 486px;
                left: 760px;

                .name_div {
                    margin-left: 70px;
                }

                .text_div {
                    width: 224px;
                    margin-top: 32px;
                }
            }

            .preferentialPolicies_li:nth-child(4) {
                top: 658px;
                left: 462px;

                .name_div {
                    margin-left: 80px;
                }

                .text_div {
                    width: 272px;
                    margin-top: 16px;
                }
            }

            .preferentialPolicies_li:nth-child(5) {
                top: 486px;
                left: 232px;

                .name_div {
                    margin-left: 58px;
                }

                .text_div {
                    width: 207px;
                    margin-top: 29px;
                    text-align: center;
                }
            }

            .preferentialPolicies_li:nth-child(6) {
                top: 200px;
                left: 208px;

                .name_div {
                    margin-left: 80px;
                }

                .text_div {
                    width: 240px;
                    margin-top: 29px;
                    text-align: center;
                }
            }

            .preferentialPolicies_li {
                position: absolute;

                .name_div {
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .text_div {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                }
            }
        }

        .btn {
            width: 428px;
            height: 48px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 870px;
        }
    }

    .module2 {
        height: 740px;

        .category_div {
            margin-top: 37px;

            .category_list:nth-child(1) {
                margin-left: 0 !important;
            }

            .category_list {
                width: 380px;
                height: 456px;
                box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.26);
                margin-left: 28px;

                img {
                    width: 380px;
                    height: 260px;
                }

                .list_cont {
                    margin-top: 19px;

                    .list_title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .list_text {
                        width: 288px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 12px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module3 {
        height: 724px;
        background: #fafafa;

        .way_div {
            margin-top: 57px;

            .way_div_fl {
                width: 736px;

                .way_fl_li {
                    width: 366px;
                    height: 114px;
                    border-bottom: 1px solid #f0f0f0;

                    img {
                        width: 68px;
                        height: 68px;
                    }

                    .way_contetn {
                        margin-left: 20px;

                        .head {
                            .num {
                                font-size: 36px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #003c7e;
                                line-height: 24px;
                            }

                            .title {
                                font-size: 24px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #003c7e;
                                line-height: 24px;
                            }
                        }

                        .way_text {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 15px;
                        }
                    }
                }
            }

            .way_div_fr {
                img {
                    width: 460px;
                    height: 320px;
                    margin-left: 8px;
                }
            }
        }

        .btn {
            width: 428px;
            height: 48px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 78px;
        }
    }

    .module4 {
        height: 960px;
        background: url('../../assets/img/applyHouseholdAccount/bg4.png') no-repeat;
        background-size: 100% 100%;
    }

    .module5 {
        .housekeeper_div {
            width: 100%;
            height: 760px;
            background: url('../../assets/img/applyHouseholdAccount/bg3.png') no-repeat;
            background-size: 100% 100%;

            .housekeeper_ul {
                position: relative;

                .housekeeper_li:nth-child(1) {
                    top: 110px;
                    left: 175px;
                }

                .housekeeper_li:nth-child(2) {
                    top: 110px;
                    left: 405px;
                }

                .housekeeper_li:nth-child(3) {
                    top: 110px;
                    right: 395px;
                }

                .housekeeper_li:nth-child(4) {
                    top: 110px;
                    right: 168px;
                }

                .housekeeper_li:nth-child(5) {
                    top: 375px;
                    left: 175px;
                }

                .housekeeper_li:nth-child(6) {
                    top: 375px;
                    left: 405px;
                }

                .housekeeper_li:nth-child(7) {
                    top: 375px;
                    right: 395px;
                }

                .housekeeper_li:nth-child(8) {
                    top: 375px;
                    right: 168px;
                }

                .housekeeper_li {
                    width: 165px;
                    position: absolute;

                    .service_way {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }

                    .btn {
                        width: 108px;
                        height: 38px;
                        background: #ff881e;
                        border-radius: 5px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .module6 {
        background: #f4f5f5;
        padding-bottom: 62px;

        .security_interest {
            margin-top: 31px;

            ul {
                .security_interest_list:nth-child(3n + 1) {
                    margin-left: 0 !important;
                }

                .security_interest_list {
                    width: 378px;
                    height: 130px;
                    background: #ffffff;
                    border: 1px solid #dddddd;
                    margin-left: 28px;
                    margin-bottom: 38px;

                    img {
                        width: 88px;
                        height: 88px;
                        margin: 0px 24px;
                    }

                    .list_content {
                        width: 180px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .module7 {
        background: url('../../assets/img/applyHouseholdAccount/bg5.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 97px;

        .content {
            margin-top: 31px;
        }
    }

    .module8 {
        background: #fafafa;
    }
}</style>