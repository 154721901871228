<template>
	<div class="course_Title display column">
		<div class="title" :class="isWhite == 1 ? 'isWhite' : ''">{{ title }}</div>
		<div class="cont_text" :class="isWhite == 1 ? 'isWhite' : ''">
			{{ contText }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {},
		contText: {},
		isWhite: {},
	},
	data() {
		return {}
	},
}
</script>
<style lang="scss" scoped>
.course_Title {
	padding-top: 82px;
	.title {
		font-size: 32px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
		z-index: 6;
	}

	.cont_text {
		width: 700px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
		text-align: center;
		margin-top: 19px;
	}
	.isWhite {
		color: white !important;
	}
}
</style>