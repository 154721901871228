<template>
    <div class="takeTest_page">
        <div class="formData">
            <div class="list" v-for="(item, index) in takeTestData" :key="index">
                <div v-if="item.type == 1">
                    <div class="label">{{ index + 1 }}、{{ item.label }}</div>
                    <div class="operation">
                        <el-radio-group v-model="item.radio">
                            <el-radio :name="items.label" @change="getRadioRow(item, items, index)" :label="items.id"
                                v-for="(items, indexs) in item.children" :key="indexs">{{ items.label }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div v-else>
                    <div class="label">{{ index + 1 }}、{{ item.label }}</div>
                    <div class="operation flex-center" v-for="(items, indexs) in item.children" :key="indexs">
                        <input @input="getRadioRow(item, items, index)" maxlength="2" class="age_input"
                            v-model="item.radio" type="text" placeholder="请输入您的年龄" /><span class="age">岁</span>
                    </div>
                </div>
            </div>
            <div class="last_list flex-center">
                <input type="text" v-model="params.userName" placeholder="请输入您的姓名" />
                <input type="text" v-model="params.userPhone" maxlength="11" placeholder="请输入您的真实手机号" />
                <div class="submitBtn display pointer" @click="submitBtn">
                    提交获取测评结果
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        takeTestData: {},
    },
    data() {
        return {
            params: {
                userName: '',
                userPhone: '',
                userQuestionBankParamDTOS: [],
            },
        }
    },
    mounted() { },
    methods: {
        getRadioRow(item, items, index) {
            console.log(item, items, index);
            console.log(item.radio, items.label);
            if (item.radio != "") {
                this.takeTestData[index].answer = item.label
                let obj = {
                    questionBankId: item.id,
                    questionBankName: item.label,
                    answerId: items.id,
                    answerContent: item.type == 2 ? item.radio + items.label : items.label,
                }
                this.params.userQuestionBankParamDTOS[index] = obj
            }

        },
        submitBtn() {
            var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
            let userQuestionBankParamDTOS = this.params.userQuestionBankParamDTOS
            let arr = []
            for (let key in userQuestionBankParamDTOS) {
                arr.push(userQuestionBankParamDTOS[key])
            }
            if (arr.length != this.takeTestData.length) {
                return this.$message.error('请填写完整信息')
            }

            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            } else if (!reg.test(this.params.userPhone)) {
                return this.$message.error('请输入您的手机号')
            }
            this.api.addUserQuestionInfo(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功！')
                    this.params = {
                        userName: '',
                        userPhone: '',
                        userQuestionBankParamDTOS: [],
                    }
                    this.takeTestData.forEach((item) => {
                        this.$set(item, 'radio', '')
                    })
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.takeTest_page {
    .formData {
        width: 100%;
        background: #ffffff;
        border-radius: 8px;
        margin-top: 31px;
        padding: 30px 32px;
        box-sizing: border-box;

        .list {
            width: 100%;
            border-bottom: 1px dashed #999;
            padding-bottom: 15px;
            margin-bottom: 15px;

            .label {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                margin-bottom: 10px;
            }

            .operation {
                margin-left: 25px;

                .age_input {
                    width: 166px;
                    height: 40px;
                    background: #ffffff;
                    border: 1px solid #dedede;
                    border-radius: 3px;
                    font-size: 14px;
                    padding-left: 16px;
                }

                .age {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    margin-left: 14px;
                }
            }

            ::v-deep.el-radio__inner {
                width: 15px;
                height: 15px;
                border: 1px solid #333333;
            }

            ::v-deep.el-radio {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
        }

        .last_list {
            input {
                width: 243px;
                height: 40px;
                background: #ffffff;
                border: 1px solid #dedede;
                border-radius: 3px;
                margin-right: 20px;
                font-size: 14px;
                padding-left: 20px;
            }

            .submitBtn {
                width: 243px;
                height: 40px;
                background: #ff881e;
                border-radius: 3px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
}
</style>